





















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  private topActions = []

  private componentKeyDate = 0

  private itemsPerPage = 30

  private get headers() {
    const headers = []

    headers.push({
      text: 'ID',
      value: 'id',
      filter: {},
      sortable: true,
    })

    headers.push({
      text: 'Date',
      value: 'createdAt',
      filter: {},
      sortable: true,
    })

    return headers
  }

  private get url() {
    return '/v4/site/product/report/transfers/' + this.$route.params.reportProductId
  }

  private get listComponentKey() {
    return this.$route.path + this.componentKeyDate
  }

  private mounted() {
    this.topActions = [
      {
        id: 'return',
        route: () => {
          return {
            name: 'Reports/Products/List',
          }
        },
      },
    ]
  }
}
